import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import GetApikey from '../../components/extension/get-apikey/GetApikey';

import './Extension.scss';

const Extension = ({ pageContext }) => {
    const currentPage = pageContext.currentPage;
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        if (!isClient) {
            setIsClient(true);
            return;
        }

        const bodyDom = document.getElementsByTagName('body')[0];
        const dndBarDom = document.getElementsByClassName('dndBar')[0];

        dndBarDom && bodyDom.removeChild(dndBarDom);
        bodyDom.classList.remove('dndBarActive');
    }, [isClient]);

    const PageFragment = useMemo(() => {
        if (currentPage === 'get-apikey') {
            return <GetApikey />;
        }
    }, [currentPage]);

    return <section className='extension'>
        {PageFragment}
    </section>;
};

Extension.propTypes = {
    pageContext: PropTypes.object.isRequired,
};


export default Extension;
