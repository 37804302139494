import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Authenticator, logService } from '@mdc/services';

const closeWindow = () => {
    if (window) {
        window.close();
    }
};

const GetApiKey = () => {
    const { t, ready } = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                await Authenticator.init();
            } catch (err) {
                logService.error(err);
            }

            closeWindow();
        })();
    }, []);

    if (!ready) {
        return;
    }

    return <p>{t('Setting up extension')}</p>;
};

export default GetApiKey;
